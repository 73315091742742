<template>
  <div class="container">
    <transition
      name="fade"
      mode="out-in"
    >
      <!--    part 1-->

      <section
        v-if="step === 1"
        class="ion-margin-bottom"
      >
        <h4 class="ion-margin-bottom ion-text-center"> {{ $t('profile.twoFAQRModalApp.authApp') }} </h4>
        <h5 class="ion-margin-bottom">
          {{ $t('profile.twoFAQRModalApp.step1Label') }}
        </h5>
        <div class="is-flex ion-justify-content-center w-100 mb-6">
          <ion-button
            mode="ios"
            class="uppercase w-100"
            @click="openUrl(GOOGLE_AUTHENTICATOR_DOWNLOAD_APP)"
          >
            {{ $t('commonKeys.googlePlayMarket') }}
          </ion-button>
          <ion-button
            mode="ios"
            class="uppercase w-100"
            @click="openUrl(IOS_AUTHENTICATOR_DOWNLOAD_APP)"
          >
            {{ $t('commonKeys.appStore') }}
          </ion-button>
        </div>


        <h5 class="ion-margin-bottom">
          {{ $t('profile.twoFAQRModalApp.step2Label') }}
        </h5>

        <div class="is-flex ion-align-items-center">
          <ion-card color="medium ion-padding ion-margin is-flex ion-justify-content-between ion-align-items-center grow-1">
            <span class="ion-text-center"> {{ $store.state.authentication.secretKey }} </span>
            <IonIcon
              class="pointer"
              :icon="copyOutline"
              size="large"
              @click="copy($store.state.authentication.secretKey, $t('commonKeys.copyToClipBoard'))"
            />
          </ion-card>

          <IonIcon
            class="pointer shrink-0"
            :icon="refreshCircleOutline"
            size="large"
            @click="$emit('refresh')"
          />
        </div>

        <p>{{ $t('profile.twoFAQRModalApp.saveCodeHint') }}</p>
      </section>

      <!--    part 2-->

      <section
        v-else-if="step === 2"
        class="ion-margin-bottom"
      >
        <h4 class="ion-margin-bottom ion-text-center"> {{ $t('profile.twoFAQRModalApp.enterCode') }} </h4>
        <h5 class="ion-margin-bottom">
          {{ $t('profile.twoFAQRModalApp.step3Label') }}
        </h5>

        <AppInput
          class="w-100"
          type="text"
          inputmode="decimal"
          :value="verificationCode"
          :maxlength="TWO_FA_CODE_LENGTH"
          autocomplete="one-time-code"
          :errors="codeErrors"
          :icon="appsOutlineIcon"
          @input:update="verificationCode = $event"
        />
      </section>
    </transition>

    <!--  navigation-->

    <div class="is-flex ion-justify-content-center w-100 ion-margin-bottom">
      <ion-button
        v-if="step > 1"
        mode="ios"
        class="uppercase w-100"
        fill="outline"
        @click="step--"
      >
        {{ $t('commonKeys.back') }}
      </ion-button>
      <ion-button
        mode="ios"
        class="uppercase w-100"
        fill="outline"
        :disabled="nextDisabled"
        @click="handleNext"
      >
        {{ nextLabel }}
      </ion-button>
    </div>

    <slot name="footer"></slot>

    <IonLoading :is-open="loadingActive"/>
  </div>
</template>

<script>
import { IonButton, IonIcon, IonCard,  IonLoading } from '@ionic/vue';
import { appsOutline as appsOutlineIcon, copyOutline, lockClosedOutline, refreshCircleOutline} from 'ionicons/icons';

import { ref, computed } from '@vue/reactivity';

import { GOOGLE_AUTHENTICATOR_DOWNLOAD_APP, IOS_AUTHENTICATOR_DOWNLOAD_APP, TWO_FA_CODE_LENGTH } from "@/config/constants";

import AppInput from '@/components/common/AppInput.vue';

import { useStore } from 'vuex';
import useRequestToast from "@/composables/useRequestToast";
import useCountDown from "@/composables/useCountDown";
import useCopyToClipboard from "@/composables/useCopyToClipboard";
import useInAppBrowser from "@/composables/useInAppBrowser";
import { useI18n } from 'vue-i18n/index';

export default {
  name: "QRCodeInput",
  components: {
    IonButton,
    IonIcon,
    IonCard,
    AppInput,
    IonLoading,
  },

  props: {
    password: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabledTill: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { copy } = useCopyToClipboard();
    const { openUrl } = useInAppBrowser();
    const { openErrorToast } = useRequestToast();
    const { startCountDown, countDownValue } = useCountDown();


    const store = useStore();
    const { t } = useI18n();

    const loadingInternal = ref(false);
    const loadingActive = computed(() => props.loading || loadingInternal.value );

    const email = computed(() => store.getters['authentication/getEmailState']);

    const steps = ref(2);
    const step = ref(1);
    const verificationCode = ref(null);
    const codeErrors = ref([]);

    const nextDisabled = computed(() => {
      const finalStep = step.value >= steps.value;
      const codeFilled = !!(verificationCode.value && verificationCode.value?.length >= TWO_FA_CODE_LENGTH);
      return finalStep && (!codeFilled || !!countDownValue.value);
    });

    const nextLabel = computed(() => {
      const finalStep = step.value >= steps.value;
      if (finalStep) {
        return countDownValue.value ? countDownValue.value : t('commonKeys.next');
      }
      return t('commonKeys.next');
    });


    const verifyCode = async () => {
      loadingInternal.value = true;

      codeErrors.value = [];

      store.dispatch('profile/verifyTwoFA', {
        email: email.value,
        verify_code: verificationCode.value,
        password: props.password,
      })
        .then(() => {
          emit('confirmed', verificationCode.value);
        })
        .catch((error) => {
          const codeError = error?.response?.data?.errors?.verify_code;
          if (codeError) {
            codeErrors.value = codeError;
          }

          if (error?.response?.data?.errors?.wait_till) {
            startCountDown(error?.response?.data?.errors?.wait_till[0]);
          }

          openErrorToast(error);
        })
        .finally(async () => {
          loadingInternal.value = false;
        });
    };


    const handleNext = () => {
      if (step.value === 2) {
        verifyCode();
      } else {
        if (step.value < steps.value) {
          step.value++;
        }
      }
    };


    return {
      copyOutline,
      lockClosedOutline,
      refreshCircleOutline,
      appsOutlineIcon,

      GOOGLE_AUTHENTICATOR_DOWNLOAD_APP,
      IOS_AUTHENTICATOR_DOWNLOAD_APP,
      TWO_FA_CODE_LENGTH,

      openUrl,
      copy,
      handleNext,

      nextDisabled,
      loadingActive,
      nextLabel,
      steps,
      step,
      verificationCode,
      codeErrors,
    };
  },
};
</script>
