import useRequestToast from "./useRequestToast";
import useCountDown from "./useCountDown";

import { ref } from '@vue/reactivity';
import { useStore } from "vuex";

export default () => {
  const store = useStore();
  const open2FACodeInput = ref(false);
  const open2FAQRInput = ref(false);

  const fetching2FAQR = ref(false);

  const { startCountDown, countDownValue } = useCountDown();

  const getQrAndSecret = (email, password) => {
    fetching2FAQR.value = true;
    store.dispatch('profile/generatePublic2FAQRAndSecret', { email, password })
      .then(( { data } ) => {
        store.commit('authentication/setQrImage', data.qr_image);
        store.commit('authentication/setSecretKey', data.secret_key);
        open2FAQRInput.value = true;
      })
      .finally( () => {
        fetching2FAQR.value = false;
      });
  };


  const loginErrorHandler = (error, email, password) => {
    const { openErrorToast } = useRequestToast();
    const unprocessableEntity = error.response && error.response.status === 422;

    if (unprocessableEntity) {
      const err = error.response.data.errors;

      const waitTill = err.wait_till && err.wait_till[0];
      const requireInitRegister2FA = Boolean(unprocessableEntity &&  'verify_code' in err && err.verify_code.includes('register_2fa'));
      const require2FACode = 'verify_code' in err && err.verify_code.includes('2fa_required');

      if (waitTill) {
        startCountDown(waitTill);
        return;
      }

      if (require2FACode) {
        open2FACodeInput.value = true;
        return;
      }

      if (requireInitRegister2FA) {
        getQrAndSecret(email, password);
        return;
      }
    }

    openErrorToast(error);
  };

  return {
    countDownValue,
    open2FAQRInput,
    open2FACodeInput,
    fetching2FAQR,
    getQrAndSecret,
    loginErrorHandler,
  };
};
