import { ref } from '@vue/reactivity';
import { onScopeDispose } from 'vue';

export default () => {
  const countDownValue = ref(null);
  const countDownInterval = ref(null);
  const countDownDate = ref(null);

  const startCountDown = (secondsToWait) => {
    clearInterval(countDownInterval);

    const dateTillWait = new Date();
    dateTillWait.setSeconds(dateTillWait.getSeconds() + Number(secondsToWait));
    countDownDate.value = dateTillWait;

    updateCountDownDate();

    countDownInterval.value = setInterval(updateCountDownDate, 1000);
  };

  const updateCountDownDate = () => {
    const d = new Date();
    const now = d.getTime();

    const distance = countDownDate.value.getTime() - now;
    const minutes = Math.floor(distance / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    countDownValue.value = String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');

    if (distance < 1) {
      clearCountDown();
    }
  };

  const clearCountDown = () => {
    clearInterval(countDownInterval.value);
    countDownValue.value = null;
    countDownInterval.value = null;
    countDownDate.value = null;
  };

  onScopeDispose(clearCountDown);


  return {
    countDownValue,
    startCountDown,
    clearCountDown,
  };
};
