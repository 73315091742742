<template>
  <div>
    <p class="mb-1 fz-14"> {{ $t('profile.twoFAQRModalApp.enterCodeToContinue') }} </p>

    <div class="input pt-5">
      <AppInput
        :icon="appsOutlineIcon"
        :placeholder="$t('profile.twoFACodePlaceholder')"
        :value="code"
        inputmode="decimal"
        :maxlength="TWO_FA_CODE_LENGTH"
        autocomplete="one-time-code"
        @input:update="code = $event"
      />

      <div class="mt-5 pt-5">
        <ion-button
          expand="block"
          :disabled="disabledTill || !code"
          mode="ios"
          shape="round"
          @click="submitCode"
        >
          {{ buttonText }}
        </ion-button>
      </div>

      <div class="mt-5">
        <ion-button
          @click="lostCode=!lostCode"
          expand="block"
          shape="round"
          mode="ios"
          fill="outline"
        >
          {{ $t('profile.twoFALostHelper.iLostMyCode') }}
        </ion-button>
      </div>
    </div>


    <transition name="fade">
      <div
        v-if="lostCode"
        class="mt-4 pl-2 pr-2"
      >
        <h3 class="fz-16 fw-600 mb-2">
          {{ $t('profile.twoFALostHelper.lostCode') }}
        </h3>

        <p class="mb-4 fz-14">
          {{ $t('profile.twoFALostHelper.lostCodeHelper') }}
        </p>

        <h3 class="fz-16 fw-600 mb-2">
          {{ $t('profile.twoFALostHelper.noSafeCode') }}
        </h3>

        <ion-button
          mode="ios"
          fill="outline"
          class="uppercase fw-500 mt-5 fz-12 contact-us"
        >
          {{ $t('contact.us') }}
        </ion-button>
      </div>
    </transition>
  </div>
</template>

<script>
import { appsOutline as appsOutlineIcon } from 'ionicons/icons';
import {IonButton } from '@ionic/vue';

import AppInput from '@/components/common/AppInput.vue';
import { ref, computed } from '@vue/reactivity';
import { useI18n } from 'vue-i18n/index';
import { TWO_FA_CODE_LENGTH } from "@/config/constants";

export default {
  name: "TwoFAVerifyCodeInput",
  components: {
    AppInput,
    IonButton,
  },

  props: {
    disabledTill: {
      type: String,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: null,
    },
  },

  setup (props, { emit }) {
    const { t } = useI18n();


    const code = ref(null);
    const lostCode = ref(false);
    const submitCode = () => emit('submit', code.value);

    const buttonText = computed(() => {
      if (props.disabledTill) {
        return props.disabledTill;
      }

      return props.buttonLabel ?? t('login.loginBtnText');
    });


    return {
      code,
      lostCode,
      submitCode,
      buttonText,
      appsOutlineIcon,
      TWO_FA_CODE_LENGTH,
    };
  },
};
</script>
